:root {
    /*Margins & Paddings*/
    --padding: 1em;
    --margin: 1em;
    --vertical-padding: 1em; /* must NOT be a percentage value */
    --vertical-margin: 2em;  /* must NOT be a percentage value */
    /*Breakpoints*/
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 1100px;
    /*General*/
    --container-max-width: 1100px;
    --fixed-header-height: 80px;
    --header-min-height: 80px;
    /*Fonts*/
    --base-font-family: 'Hind', sans-serif;
    --alternative-font-family:  'Rambla', sans-serif;
    --base-font-weight: 300;
    --alternative-font-weight:400 ;
}

@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/theme/intendit/css/modules/flex-grid.css";   
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.v1.2.css";
@import "/cssmodules/magnific-popup.css";
@import "/theme/intendit/css/modules/hoverboxes.css";   
@import "/fontmodules/Quicksand.css";
@import "/fontmodules/Hind.css";
@import "/fontmodules/Rambla.css";

/* @import "/cssmodules/animate.css";*/

  











/*------------------------------------------------------------------------------------------------ 
GENERAL ----------------------------------------------------------------------------------------*/
body {color:#252525; font-family: var(--base-font-family); font-weight: var(--base-font-weight); line-height:1.2; 
/*background: url('/thumbs/1920x1080r/theme/intendit/graphics/bg.jpg') center top/cover no-repeat fixed;*/ }

/*wrappers*/
main.wrapper {min-height: 100vh; position:relative; }  
.content-wrapper {padding: 40px 0 50px; } 
.container {width:100%; }
.container.sml {max-width: 900px;}
.container.lrg {max-width: 1300px;}

/*basic styles*/
a, a:hover {color:#404040; font-weight: var(--base-font-weight); text-decoration: none;}
p {margin:0.2em 0 1.2em;}
strong {font-weight:500;}
iframe {max-width:100%;}
 
/*------------------------------------------------------------------------------------------------ 
LISTS & TABLES ---------------------------------------------------------------------------------*/
.content-wrapper ul {list-style:disc; padding: 0 0 0 15px; margin:-5px 0 0;}
.content-wrapper ul li {margin-bottom:0.2em;}
/*.content-wrapper ul {list-style:none; padding: 0; margin: 0.5em 0;}
.content-wrapper ul li {width: 48%; margin:0 2% 2% 0; float:left;} 
.content-wrapper ul li:before {content: "\2714"; margin:0 0.2em 0 0; font-size:1.2em; }*/

/*table
table {margin:30px 0; font-size:95%; width:100%;} tbody {width:100%;}
table tr {width:100%; border-bottom: 1px solid #e4e4e4;}
table tr td {margin: 0; padding:5px 30px 5px 0; vertical-align: top; border:none; } 
table tr td:last-child {padding:5px 0px 5px 0;}*/

/*egna klasser*/
.spaltX2 {column-count: 2; column-gap: 50px; text-align:left !important;} /*Delar upp i 2 spalter*/
.shadow {box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);} 
.publishdate {color:#909090; font-size:95%; display:block; clear:both;  }
.record { border-bottom:1px solid #e4e4e4;}
 
/*------------------------------------------------------------------------------------------------ 
IMAGES -----------------------------------------------------------------------------------------*/
img {max-width: 100%; height: auto;}
figure {margin:0; }
.flex > * > img {flex: 0 0 auto;}
.flex > * > img:not([class]) {width: 100%;}
/*artikelbild*/
figure.page-image {float:right; max-width:35%; height:auto; margin: 0 0 2em 2em;}
figure.page-image img {display:block; width:100%; height:auto; border-radius:50%; border:7px solid #303030; }
figure.page-image figcaption {font-style:italic; color:#606060;} /*bildtext inne på sidor*/ 
/*video bakgrundsbild*/
.listing-video {background-size:cover; background-position:center center; min-height:200px; }

.video {background-size:cover; background-position:center center; min-height:305px; width: 100%; max-width:550px; margin: 20px 0; }
.video .fa-icon {
    height: 50px; fill:#fff; 
 }


/*filelist*/ 
a.file {display:block; padding: 10px 20px; background:#e4e4e4;   border-radius: 3px; margin: 0 0 3px; font-weight:400;  }

/*------------------------------------------------------------------------------------------------ 
BUTTONS & ICONS --------------------------------------------------------------------------------*/
.button.button, .readmore {margin:0; padding: 6px 0 8px; display:block; width:100%; text-align: center; border:none; border-radius:2px; 
background: rgba(0, 0, 0, 0.1);  transition:background 0.5s ease-in 0s; }
.contact-banner .button.button {background: rgba(255, 255, 255, 0.1);}  
.readmore {max-width:100px;} 
.button.button:hover, .readmore:hover { background:rgba(0, 0, 0, 0.3);} 

/*ikoner*/
.fa-icon {height:20px; width: auto; vertical-align:middle !important; }
.round {background: rgba(0, 0, 0, 0.5); border-radius: 50%; display: inline-block; height: 40px; width: 40px; margin: 0 10px 10px 0; }
.round .fa-icon {fill:#fff; padding: 8px 0; height:40px; width: auto; display:block; margin: 0 auto; }
.round .fa-icon[aria-label="Icon depicting mobile"] {padding: 3px 0; }

.toptext .round {display:block; }
.box .round {display:block; margin:0 auto 1em; height: 80px; width: 80px;} 
.box .round .fa-icon { padding: 20px 0; height:80px; }

.listing-video .fa-icon {height: 40px; fill:#fff;}

/*------------------------------------------------------------------------------------------------ 
FONTS ----------------------------------------------------------------------------------------*/
h1, h2, h3, h4, h5, h6 {letter-spacing:0; line-height:1.1; padding: 0; margin: 0; font-family: var(--alternative-font-family); font-weight: var(--alternative-font-weight); }
h1{font-size: 2.6em; margin: 0 0 0.5em; /*text-transform:uppercase;*/ font-weight:600; }
h2{font-size: 1.65em;}
h3{font-size: 1.3em; margin-top:30px; }  
h4, h5, h6 {font-size: 1.3em; }

h2.highlight {font-size:1.4em; padding: 0 0 0.5em; color:#1563B5; font-weight:500; }

/*------------------------------------------------------------------------------------------------ 
TOPPEN ----------------------------------------------------------------------------------------*/
.toptext { position:absolute; right:10px; top:90px; z-index:11; display:none; } /*display none by default*/
.imagetop {min-height:50vh; background-position: center center; background-size:cover; position: relative; border-bottom: 10px solid #202020; }


/*------------------------------------------------------------------------------------------------ 
STARTSIDA ----------------------------------------------------------------------------------------*/

/*slick slider*/
.slick-wrapper {min-height:100vh; position:relative; flex-wrap:wrap;  }
.slick-track, .slick-substitute {display:flex; flex-direction:row;}
.slick-slider, .slick-substitute > div, .slick-slide {min-height:100vh; margin:0; }
.slick-slider, .slick-substitute, .slick-substitute > div { width:100%; height:100%; top:0; left:0; z-index:0;}
.slick-slide, .slick-substitute > div {background-size:cover; background-position: center center; display:flex !important;}   
.slick {visibility: hidden;} .slick-initialized {visibility: visible;} .slick-list, .slick-track {height:100%;}

/*Slick title*/ 
.slick-title {width:100%; text-align:center; font-size: 1.5em; line-height:1.1em; padding: 30px 0; }
.slick-title h2 {font-size: 2em;}
 
/*Starttext - aktivera nedersta för starttext inuti slick-wrapper*/ 
.starttext {padding: 30px 0; width:100%;  text-align:center; z-index: 0; min-height: 20vh; color:#e4e4e4; margin-top: 40vh; 
/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.4+0,1+100 */
background: -moz-linear-gradient(top, rgba(0,0,0,0.4) 0%, rgba(0,0,0,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, rgba(0,0,0,0.4) 0%,rgba(0,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, rgba(0,0,0,0.4) 0%,rgba(0,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#66000000', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}
.starttext h1 {margin: 0; font-size: 3em; }
.starttext p {margin-bottom:0; font-size: 1.2em; line-height:1.1; } 
.slick-slider, .slick-substitute > div { position:absolute; width:100%; height:100%;  top:0; left:0; z-index:-1; }

/*Slick arrows
.slick-prev, .slick-next {width:100px; height:100px; border:none;position:absolute; z-index:1; }  
.slick-prev {left:0px;} .slick-next {right:0px;}
.slick-prev:before, .slick-next:before { font-size:100px; color:#fff; opacity:0.9; font-family: Quicksand, sans-serif; }
.slick-prev:before {content:"<";} .slick-next:before {content:">";} */

/*Slick dots
.slick-dots {margin:0; bottom: 10px;  }
.slick-dots li button {background:#000; border-radius:50%; width:14px; height:14px; opacity:0.6; transition: opacity 0.5s ease-in 0s;  }
.slick-dots > .slick-active > button {opacity:1; }
.slick-dots li button:before {color: transparent; content: "";}*/

/*----------------------Boxar-------------------------------*/
.boxes {padding: 1.5em 0; }
.box {/*box-shadow: 0px 0px 0px 1px #e4e4e4;*/}
.box h2 {}
.box-text {}
.box-text p:last-of-type { margin:0.2em 0 0.5em;}

/*------------------------------------------------------------------------------------------------ 
CONTENT----------------------------------------------------------------------------------------*/
 
/*blogg*/
.blog-title {margin-bottom:0;}
.blog-taxonomy > div { border-left: 2px solid #d0d0d0; padding: 1em 0 2em 1em; }
.categories-wrapper a {display:block; }

/*gallery*/
.image-gallery, .logotype-gallery {padding: 2em 0 0; margin-top:1em; border-top: 1px solid #d0d0d0; }
.gallery-image {box-shadow: 1px 1px 0 0 #e4e4e4;} .gallery-image:nth-child(5n + 5) {box-shadow: 0px 1px 0 0 #e4e4e4;}
.gallery-image a {display:block; }
.gallery-image img {padding:20px; display: block;}
.thumb-title {font-size:90%; padding: 0 20px 20px; display:none; }  

/*logo gallery*/
.logotype-image {min-height: 70px; } 
.logotype-image div, .logotype-image a {text-align:center; width: 100%; flex:0 0 auto; display:block;} 
.logotype-image img {height:auto;} .standing  {width:40%; } .squarish  {width:40%; } .landscape  {width:55%; }.longlandscape {width:70%; }.extralonglandscape {width:100%; }

/*magnific styling*/ 
.mfp-bg {background: #000; opacity:0.9;}  .mfp-arrow {top:40%; } 
.mfp-arrow, .mfp-arrow:before, .mfp-arrow-left:before, .mfp-arrow-right:before,.mfp-arrow:after, .mfp-arrow-left:after, .mfp-arrow-right:after {border:none ; margin-left:0; margin-right:0;  }
.mfp-arrow-left:before {content:"<"; } .mfp-arrow-right:before {content:">"; }
.mfp-arrow-left:before, .mfp-arrow-right:before {height:100px; width:100px; color:#fff; font-size:5.5em; font-family: 'Quicksand', sans-serif; opacity:0.9;}
.mfp-arrow-left {margin-left:20px; }  .mfp-arrow-right {margin-right:20px; } 
button.mfp-close { font-size: 4em; margin:0 -2px 0 0; opacity: 0.9; font-family: Quicksand; }
  
/*------------------------------------------------------------------------------------------------ 
FOOTER & KONTAKT--------------------------------------------------------------------------------*/
/*kontaktbanner & formulär*/
.boltforms-row label, .boltforms-row button {font-weight:400;  margin-bottom: 0.2em;}
.boltforms-row input, .boltforms-row textarea {width:100%; color:#606060;  }
.contact-banner {background: #1563B5; padding:50px 0; color:#fff; }
.contact-banner .button, .contact-banner .button:hover  {color:#fff;}
.contact-banner .boltforms-row {float:left; width:33.3%; padding-right:10px;}
.contact-form .boltforms-row, .contact-banner .boltforms-row:nth-child(n+4) {width:100%;}

/*karta*/
.map-canvas {height: 35vh; margin-top:2em;}
.map-canvas img {max-width: none;}
.markerLabels{ overflow:visible !important;}
.fa-map-marker{background: url("/extensions/local/intendit/intenditExt/web/black/map-marker.svg") center center/cover; padding: 10px;}

/*footer*/
footer {padding: 50px 0 120px; width:100%; background:#000;}
footer, footer a, footer a:hover {color:#b0b0b0; }
footer h4 { padding: 0 0 5px; font-size:1.3em; } 
footer .round {background: rgba(255, 255, 255, 0.2);}
footer svg {fill:#fff; }
/*sticky*/
html.sticky {height:100%;} .sticky body {display:flex; flex-direction:column; min-height:100%;}
.sticky main.wrapper {flex: 1 0 auto; min-height:1em; } .sticky header, .sticky footer {flex: 0 0 auto; }

/*intendit*/
.created-by {text-align: center; padding: 8px 0; background:#101010;}
.created-by p, .created-by a {color:#707070; font-size: 0.9em; letter-spacing: 0.5pt; margin: 0;}

/*------------------------------------------------------------------------------------------------ 
HEADER ----------------------------------------------------------------------------------------*/
header {background:rgba(0, 0, 0, 0.8); position:fixed; ;width:100%; top:0;  z-index:10; min-height:var(--header-min-height); font-family: var(--base-font-family);}
body.scrolled header {}

/*Logotype*/
.logo {padding:10px 15px; box-sizing:border-box; } 
.logo a, .logo a:hover {padding:0; display:block; font-size:2em; } 
.logo img {max-height: 75px; width:auto; max-width:calc(100vw - 100px); display:block;  }

/*Standard meny + hovers*/
nav {background:none; } 
header nav ul.menu > li {padding: 0 10px; margin:0; border-right:1px solid #e4e4e4;}
nav ul.menu > li:last-child {border:none; }
nav ul.menu li a { padding: 4px 5px; margin:0; letter-spacing:0;color:#e4e4e4;  /*transition:color 0.5s ease-in 0s;*/}
nav ul.menu li a:hover, nav ul.menu li.active a {color:#e4e4e4;}
nav ul.menu li.dropdown > a:after { content: ">"; transform: rotate(90deg); vertical-align: middle; display: inline-block; font-family: 'Quicksand', sans-serif;  font-size: 1em; margin: 0 0 0 5px;  }
nav .menu .dropdown ul  {border:none; padding: 10px 0; left:20px; }
nav ul.menu > li:last-child > ul { left: auto;right: 0; text-align:right; }
.menu .dropdown li {background: rgba(0, 0, 0, 0.8) }


/*Mobile menu - fixed header*/
@media (max-width: var(--breakpoint-menu)){
header {position:fixed; min-height:var(--fixed-header-height); box-shadow:none; flex-wrap:nowrap;  } /*background på header bar*/
.wrapper { } 
.logo img {max-height: 65px;  }
nav ul.menu.show { max-height:calc(100vh - var(--fixed-header-height)); padding: 10px 0; background:#000;}  /*räknar höjd - fixed header*/
nav ul.menu li, .mobile-menu nav ul.menu li a, .mobile-menu nav .menu .dropdown ul {border:none; background:none; }
nav .menu .dropdown ul {padding:0; left:0px;} 
nav ul.menu li.dropdown > a:after {display:none; }
.menu .sub-menu-items li a { margin: 0;}   
    
    header nav ul.menu > li { border:none;}
/*menu icon*/
nav #menu-icon {align-self: flex-start; padding: 20px 20px 20px 0;}
nav #menu-icon span, nav #menu-icon:before, nav #menu-icon:after { border-radius:3px; color:#fff;}
}


/*------------------------------------------------------------------------------------------------ 
MEDIA QUERIES ----------------------------------------------------------------------------------*/

/*----------------------------Allt under 1300px----------------------------*/
@media (max-width: 1300px) 
{  
/* Standard meny - tight it up*/ 
nav ul.menu li {padding:0 5px; }      
nav ul.menu li a {}   
    
/*Container*/
.container.lrg {width: 96%;}   
header.container.lrg {width: 100%;}   
    
}
   
/*----------------------------Allt under 1100px----------------------------*/
@media (max-width: var(--breakpoint-m))
{
/*Startsida*/ 
.starttext {}
.starttext h1 { }
.starttext p {  } 
    
/*Containers*/
.container {width: 96%;}  
header.container {width: 100%;}  
    
/*slick arrows*/   
.slick-prev, .slick-next { height: 70px; width: 70px;}
.slick-prev:before, .slick-next:before { font-size: 70px;}   
}

/*----------------------------Allt under 800px----------------------------*/
@media (max-width: var(--breakpoint-s))
{
    header {background:#000; min-height:80px; }   
    .wrapper {margin-top: 80px; }
    .homepage .hoverboxes { 
    padding: 20px 0;
}
/*Startsida*/ 
.starttext {padding-top:1.2em; padding-bottom:1.2em; margin-top: 200px;  min-height: 10px; }
.starttext h1 {font-size:2.2em;}
.starttext p {font-size: 1.1em; line-height:1em;} 
    
/*Fontsize*/ 
body {font-size: 0.95em; }
    
/*Slider & topbild height */ 
.slick-wrapper, .slick-slider, .slick-substitute > div, .slick-slide  {min-height:65vw;}
 .imagetop {min-height:55vw;}

/*gallerithumbs*/
.gallery-image:nth-child(3n+3) {box-shadow: 0 1px 0 0 #e4e4e4;}
.gallery-image:nth-child(5n+5) {box-shadow: 1px 1px 0 0 #e4e4e4;}  
    
/*Bild*/
figure.page-image {margin: 0 0 1.3em 1.3em;}
    
/*Containers*/
.container.sml {width: 96%;}   
   
/*Footer*/   
footer .s-twelve {border-bottom: 1px solid #222222; margin: 0 auto; max-width: 400px; padding: 20px 0; text-align: center; }
footer .s-twelve:last-child {border-bottom: none; }   
}


/*---------------------------MEDIA XS - upp till 550-----------------------------*/
@media (max-width: var(--breakpoint-xs))
{
/*font*/    
h1 { font-size: 2.2em;}
    
    
/*Startsida*/ 
.starttext {padding-top:1em; padding-bottom:1em;  margin-top: 120px; }
.starttext h1 {font-size:2em;}
.starttext p {} 
    
/*fixa box-marginaler*/
.margin > .twelve, .margin > .m-twelve, .margin > .s-twelve, .margin > .xs-twelve {margin: 0 0 1em; flex-basis: 100%;}
    
/*image*/
figure.page_image {margin: 0 0 10px 10px; padding:0; border:none;  }   
    
/*sidfot*/
footer  {font-size: 1.1em; line-height: 1.5; word-spacing: 2pt; padding: 30px 0 60px; }     
    
/*spalt*/    
.spaltX2 {-webkit-column-count: 1; -moz-column-count: 1; column-count: 1; -webkit-column-gap: 0px; -moz-column-gap: 0px; column-gap: 0px; text-align:none;} 
   
/*Kontaktbanner*/   
.contact-banner {padding: 30px 0;}
.contact-banner .boltforms-row {width:100%; padding-right:0px;}

/*gallerithumbs*/
.gallery-image:nth-child(3n+3) {box-shadow: 1px 1px 0 0 #e4e4e4;} 
.gallery-image:nth-child(2n+2) {box-shadow: 0 1px 0 0 #e4e4e4;} 
.gallery-image img {padding: 0.8em;}    
    
/*map*/
.map-canvas {width:100%; max-height:20vh;} 
    
/*table
table tr td { width:100% !important; margin: 0 !important; padding:0 !important ; display: inline-block;}
table tr:first-child {}
table tr td:first-child {padding-top:5px !important; }
table tr td:last-child {padding-bottom:5px !important; }*/  
    
/*blogg*/
.blog-taxonomy { padding: 0 0 1.5em; margin: 0 0 1.5em; border-bottom: 2px solid #d0d0d0;}   
.blog-title {order:-1; } .blog-taxonomy {order:0;} .blog-text {order:1; }
.blog-taxonomy > div  {padding: 0 0 2em; border: none; } 
}

@media (max-width: 350px)
{
h1, h2, h3, h4, h5, h6, a {overflow-wrap: break-word; word-wrap: break-word;word-break: break-all;word-break: break-word;}
h1, h2, h3, h4, h5, h6 { hyphens: auto;}
  
/*font*/    
h1 {font-size:1.8em !important; }  

/*start*/
.slick-wrapper .starttext p {display:none;} 
    
/*bild*/
figure.page-image {width:100%; max-width:100%; margin: 0 0 1em; float:none; }     
}

 
/*------------------------------------------------------Make FB widget responsive-------------------------------------------------------*/
.fb_iframe_widget, .fb_iframe_widget span, .fb_iframe_widget iframe[style], .twitter-timeline {width: 100% !important;}
